import axios from 'axios';
import Env from '../../config/system/env.json';

const authMultipartConnection = () => {
  const serverConnection = axios.create({
    baseURL: Env.ArirangApi_Live,

    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return serverConnection;
};

const authJsonConnection = () => {
  const serverConnection = axios.create({
    baseURL: Env.ArirangApi_Live,

    headers: {
      'Content-Type': 'application/json',
    },
  });
  return serverConnection;
};

export const authMultipartInstance = authMultipartConnection();
export const authJsonInstance = authJsonConnection();

