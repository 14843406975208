import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import { programBoardList } from '@/network/apis/apiUri';

import useContent from '@/localization/data_export';
import dayjs from 'dayjs';

const BANNER_COOKIE_NAME = 'a_b';

export default function TopBanner() {
  const location = useLocation();

  const { mode } = useSelector((state) => state);

  const anchorTitle = useContent(['global', 'anchor', 'title']);
  const boardCustomType = useContent('boardCustomType');

  const bannerBoardId = boardCustomType ? boardCustomType.csv.find((v) => v.type === '9').id : 7;

  const isHome = location.pathname === '/';

  const [bannerOpen, setBannerOpen] = useState(document.cookie.includes(BANNER_COOKIE_NAME));

  const { isSuccess, data: res } = useQuery(
    ['top-banner', bannerBoardId, mode],
    () =>
      programBoardList({
        lan_code: mode,
        title: '',
        board_id: bannerBoardId,
        content_category: '',
        event_category: null,
        is_open_allow: true,
        is_blind_allow: false,
        hit: true,
        page_info: { row_count: -1, number: 0 },
      }),
    {
      enabled: !!bannerBoardId && !!mode && !bannerOpen,
      retry: false,
      staleTime: Infinity,
    }
  );

  const setCookie = () => {
    const expireDate = dayjs().add(3, 'day').utc().toString();

    document.cookie = BANNER_COOKIE_NAME.concat('=', expireDate, ';', 'expires=', expireDate);
  };

  const handleBannerClose = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const isCheck = formData.get('setting');

    if (isCheck) setCookie();

    setBannerOpen(true);
  };

  const isBannerOpen = isHome && !bannerOpen && isSuccess && res.data.paging_info.total_item_count > 0;

  return (
    <div className="top-banner-container">
      <div className="gov">
        <div className="gov__content">
          <img src={require('@/assets/images/layout/korea.svg').default} alt="korea taegeukgi" />
          <p>This website is the official e-government website of the Republic of Korea</p>
        </div>
      </div>
      {isBannerOpen && (
        <div className="banner">
          <Swiper slidesPerView={1} autoplay={{ delay: 5000 }}>
            {res.data.item.map((v, i) => (
              <SwiperSlide key={v.board_item_id}>
                <a href={v.url_link[0]} target="_blank" rel="noreferrer" className="banner__item" title={anchorTitle}>
                  <img src={v.image.url} alt="" height={80} />
                  <form onSubmit={handleBannerClose} className="banner__close-box">
                    <label>
                      {mode === 'en' ? 'Not seen for 3 days' : '3일 동안 보지 않기'}
                      <input type="checkbox" name="setting" />
                    </label>
                    <button type="submit" value="close btn" />
                  </form>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}
