import Papa from 'papaparse';

const setTableDataFile = async (csvFile, valueSet = null) => {
  const response = await fetch(csvFile);
  if (!response.ok) throw new Error(response);
  const csv = await response.text();
  var resultJsonArray = [];
  Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
    fastMode: true,
    step: function (results, parser) {
      if (valueSet) {
        if (results.data[valueSet].indexOf('.') !== -1) {
          const valueArray = results.data[valueSet].split('.');
          results.data[valueSet] = valueArray;
        }
      }
      return resultJsonArray.push(results.data);
    },
  });
  const csvClassObject = new JsonClass(resultJsonArray);
  return csvClassObject;
};

export default setTableDataFile;
class JsonClass {
  constructor(value) {
    this.csv = value;
  }
  getList() {
    return this.csv;
  }
  getData(id) {
    if (this.csv.find((el) => el.id === id) === undefined) {
      return undefined;
    } else {
      return this.csv.find((el) => el.id === id);
    }
  }
  getValue(id, val) {
    const data = this.csv.find((el) => el.id === id);
    if (data === undefined) {
      return undefined;
    } else {
      if (data[val] === undefined) {
        return undefined;
      } else {
        return data[val];
      }
    }
  }
  getSortList() {
    const data = this.csv;
    const sorting = data.sort(function name(a, b) {
      return a.order - b.order;
    });
    return sorting;
  }
  getArrValue(group) {
    const arr = this.csv.filter((v) => v.groupid === group);
    return arr;
  }

  getSnsList(sns) {
    const arr = this.csv.filter((v) => v.category_name === sns);
    return arr;
  }
}
