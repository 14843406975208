const jsonToFormData = (jsonParam) => {
  let formData = new FormData();
  let jsonData = {};
  if (!jsonParam) {
    return;
  }
  for (const [key, value] of Object.entries(jsonParam)) {
    if (key === 'files') {
      for (let i = 0; i < value.length; i++) {
        formData.append('files', value[i]);
      }
    } else {
      jsonData[key] = value;
    }
  }
  formData.append('data', JSON.stringify(jsonData));
  return formData;
};

export default jsonToFormData;
