import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ko from './translation/ko.json';
import en from './translation/en.json';

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  resources: {
    en: { translation: en },
    ko: { translation: ko },
  },
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
