export const LIVE_WORLD_GLOBAL =
  'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoyNTU1OTcxMjAwLCJtYyI6eyIwIjp7Im1ja2V5IjoieTU0ZEhUS1QiLCJ0aXRsZSI6IkFyaXJhbmcgVFYiLCJsaXZlIjp7InVybCI6Imh0dHBzOlwvXC9hbWRsaXZlLWNoMDEtZy1jdG5kLWNvbS5ha2FtYWl6ZWQubmV0XC9hcmlyYW5nXzFnY2hcL3NtaWw6YXJpcmFuZ18xZ2NoLnNtaWxcL3BsYXlsaXN0Lm0zdTgiLCJwb3N0ZXJfdXJsIjoiaHR0cHM6XC9cL2ltZy5hcmlyYW5nLmNvbVwvdjFcL0FVVEhfZDUyNDQ5YzE2ZDNiNGJiY2ExN2Q0ZmZmZDlmYzQ0YWZcL2FyaXJhbmdcL3N0YXRpY1wvbWVkaWFcL3NjaGVkdWxlX2xpdmUxLjQ3OTk0NjlhLnBuZyJ9fX19.tGyn5GxOKSK9xjEhzvhF97DvXGc2knkivfxHxYdKnjY&custom_key=2488d22a619a018210ee7f7d65118ef38c6dad49b8fd0297b84118c26c7272cc&player_version';
export const LIVE_WORLD_KOREA =
  'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoyNTU1OTcxMjAwLCJtYyI6eyIwIjp7Im1ja2V5IjoiUEhnVkhUTEciLCJ0aXRsZSI6IkFyaXJhbmcgVFYiLCJsaXZlIjp7InVybCI6Imh0dHBzOlwvXC9hbWRsaXZlLWNoMDEtY3RuZC1jb20uYWthbWFpemVkLm5ldFwvYXJpcmFuZ18xY2hcL3NtaWw6YXJpcmFuZ18xY2guc21pbFwvcGxheWxpc3QubTN1OCIsInBvc3Rlcl91cmwiOiJodHRwczpcL1wvaW1nLmFyaXJhbmcuY29tXC92MVwvQVVUSF9kNTI0NDljMTZkM2I0YmJjYTE3ZDRmZmZkOWZjNDRhZlwvYXJpcmFuZ1wvc3RhdGljXC9tZWRpYVwvc2NoZWR1bGVfbGl2ZTEuNDc5OTQ2OWEucG5nIn19fX0.patG5SP3itjZtmoN9LBNC0R_RUql41Ppwt-jGFHYgzY&custom_key=2488d22a619a018210ee7f7d65118ef38c6dad49b8fd0297b84118c26c7272cc&player_version';
export const LIVE_UN =
  'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoyNTU1OTcxMjAwLCJtYyI6eyIwIjp7Im1ja2V5Ijoid2pxaUhUTjYiLCJ0aXRsZSI6IkFyaXJhbmcgVFYiLCJsaXZlIjp7InVybCI6Imh0dHBzOlwvXC9hbWRsaXZlLWNoMDItY3RuZC1jb20uYWthbWFpemVkLm5ldFwvYXJpcmFuZ18yY2hcL3NtaWw6YXJpcmFuZ18yY2guc21pbFwvcGxheWxpc3QubTN1OCIsInBvc3Rlcl91cmwiOiJodHRwczpcL1wvaW1nLmFyaXJhbmcuY29tXC92MVwvQVVUSF9kNTI0NDljMTZkM2I0YmJjYTE3ZDRmZmZkOWZjNDRhZlwvYXJpcmFuZ1wvc3RhdGljXC9tZWRpYVwvc2NoZWR1bGVfbGl2ZTIuMTdlYmM3MzMucG5nIn19fX0.MEAJ9z0b_I2RdsxiWo5Ux0VsTgldq-1clqqF8drDFJU&custom_key=2488d22a619a018210ee7f7d65118ef38c6dad49b8fd0297b84118c26c7272cc&player_version';
export const LIVE_KOREA =
  'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoyNTU1OTcxMjAwLCJtYyI6eyIwIjp7Im1ja2V5IjoiaXNjOUhUT0wiLCJ0aXRsZSI6IkFyaXJhbmcgVFYiLCJsaXZlIjp7InVybCI6Imh0dHBzOlwvXC9hbWRsaXZlLWNoMDQtY3RuZC1jb20uYWthbWFpemVkLm5ldFwvYXJpcmFuZ180Y2hcL3NtaWw6YXJpcmFuZ180Y2guc21pbFwvcGxheWxpc3QubTN1OCIsInBvc3Rlcl91cmwiOiJodHRwczpcL1wvaW1nLmFyaXJhbmcuY29tXC92MVwvQVVUSF9kNTI0NDljMTZkM2I0YmJjYTE3ZDRmZmZkOWZjNDRhZlwvYXJpcmFuZ1wvc3RhdGljXC9tZWRpYVwvc2NoZWR1bGVfbGl2ZTMuN2JmMmMwOWIucG5nIn19fX0.NW-czq9Zn5wllqZv8Y3QCTRTpOMWpI-WD68i1WowAUQ&custom_key=2488d22a619a018210ee7f7d65118ef38c6dad49b8fd0297b84118c26c7272cc&player_version';
export const LIVE_RADIO =
  'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHB0IjoyNTU1OTcxMjAwLCJtYyI6eyIwIjp7Im1ja2V5IjoiQkxKb0hUT0IiLCJ0aXRsZSI6IkFyaXJhbmcgVFYiLCJsaXZlIjp7InVybCI6Imh0dHBzOlwvXC9hbWRsaXZlLWNoMDMtY3RuZC1jb20uYWthbWFpemVkLm5ldFwvYXJpcmFuZ18zY2hcL3NtaWw6YXJpcmFuZ18zY2guc21pbFwvcGxheWxpc3QubTN1OCIsInBvc3Rlcl91cmwiOiJodHRwczpcL1wvaW1nLmFyaXJhbmcuY29tXC92MVwvQVVUSF9kNTI0NDljMTZkM2I0YmJjYTE3ZDRmZmZkOWZjNDRhZlwvcHVibGljXC9vbGRcL0ltYWdlc1wvcmFkaW9cL1JhZGlvXzIwMjEwOS5wbmcifX19fQ.I1PdD1yLYs0P_e-vtteDvzUibcHQcDWk4_PypmE1z30&custom_key=2488d22a619a018210ee7f7d65118ef38c6dad49b8fd0297b84118c26c7272cc&player_version';
