import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import qs from 'query-string';
const location = window.location.search;

export const NEWS_CATEGORY = 'NEWS/CATEGORY';
export const NEWS_SCHEDULE = 'NEWS/SCHEDULE';

let query = qs.parse(location);
const lang = query.lang;

const initNews = { category: '', schedule: '' };

var initState = lang ? lang : 'en';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const rootReducer = combineReducers({
  news,
  mode,
});

function news(state = initNews, action) {
  switch (action.type) {
    case NEWS_CATEGORY:
      return { ...state, category: action.category };
    case NEWS_SCHEDULE:
      return { ...state, schedule: action.schedule };
    default:
      return state;
  }
}

function mode(state = initState, action) {
  if (action.type === 'CHANGE_MODE') {
    return action.mode;
  }
  return state;
}

export default persistReducer(persistConfig, rootReducer);
