import axios from 'axios';
import { authJsonInstance, authMultipartInstance } from '../utils/header';
import jsonToFormData from '../utils/jsonToFormData';

const sessionStorageOrDefault = (key, authConnection) => {
  const userData = JSON.parse(localStorage.getItem(key));
  const tmpToken = userData?.token;
  if (tmpToken) {
    authConnection.defaults.headers.common['Authorization'] = `Bearer ${tmpToken}`;
  }
};

export const programList = (params) => {
  return authJsonInstance.post('/v1.0/open/program/list', params);
};

export const programDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/program/detail', params);
};

export const tvProgramDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/program/detail', params);
};

export const programSnsList = (params) => {
  return authJsonInstance.post('/v1.0/open/program/sns/list', params);
};

export const programMenuList = (params) => {
  return authJsonInstance.post('/v1.0/open/menu/list', params);
};

export const programCornerList = (params) => {
  return authJsonInstance.post('/v1.0/open/corner/list', params);
};

export const programCategoryList = (params) => {
  return authJsonInstance.post('/v1.0/open/category/list', params);
};

export const programBoardList = (params) => {
  return authJsonInstance.post('/v1.0/open/board/item/list', params);
};

export const programBoardDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/board/item/detail', params);
};

export const programBoardMessageDetail = (params) => {
  sessionStorageOrDefault('userData', authJsonInstance);
  return authJsonInstance.post('/v1.0/open/board/item/detail', params);
};
export const programBoardAuthDetail = (params) => {
  sessionStorageOrDefault('userData', authJsonInstance);
  return authJsonInstance.post('/v1.0/open/board/item/detail', params);
};

export const programTvMainList = (params) => {
  return authJsonInstance.post('/v1.0/open/view/slide/list', params);
};

export const schduleList = (params) => {
  return authJsonInstance.post('/v1.0/open/external/proxy', params);
};

export const trackList = (params) => {
  return authJsonInstance.post('/v1.0/open/program/tracklist/list', params);
};

export const trackDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/program/tracklist/detail', params);
};

export const userAuth = (params) => {
  return authJsonInstance.post('/v1.0/open/online/auth', params);
};

export const newsDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/media/news/detail', params);
};

export const getNewsList = (params) => {
  return authJsonInstance.post('/v1.0/open/media/news/list', params);
};

export const cornerDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/corner/detail', params);
};

export const BoardItemProgramList = (params) => {
  return authJsonInstance.post('/v1.0/open/board/item/program/list', params);
};

export const vodDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/media/vod/detail', params);
};
export const vodList = (params) => {
  return authJsonInstance.post('/v1.0/open/media/vod/list', params);
};

export const episodeList = (params) => {
  return authJsonInstance.post('/v1.0/open/media/episode/list', params);
};

export const episodeDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/media/episode/detail', params);
};

export const boardCategoryList = (params) => {
  return authJsonInstance.post('/v1.0/open/board/category/list', params);
};

export const CornerList = (params) => {
  return authJsonInstance.post('/v1.0/open/corner/list', params);
};

export const boardDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/board/detail', params);
};

export const nicknameUpdate = (params) => {
  sessionStorageOrDefault('userData', authJsonInstance);
  return authJsonInstance.post('/v1.0/open/online/user/me/nickname/update', params);
};
export const boardItemInsert = (params) => {
  sessionStorageOrDefault('userData', authMultipartInstance);
  return authMultipartInstance.post('/v1.0/open/board/item/insert', jsonToFormData(params));
};
export const boardItemUpdate = (params) => {
  sessionStorageOrDefault('userData', authMultipartInstance);
  return authMultipartInstance.post('/v1.0/open/board/item/update', jsonToFormData(params));
};
export const reporterMainList = (params) => {
  return authJsonInstance.post('/v1.0/open/reporter/list', params);
};
export const reporterDetail = (params) => {
  return authJsonInstance.post('/v1.0/open/reporter/detail', params);
};

export const geoip = () => {
  return axios.post('https://geoip.arirang.com/v1.0/open/geoip');
};
